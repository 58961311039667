<template>
    <div>
        <b-card>
            <b-row>
                <b-col cols="5">
                    <div v-if="visa" class="px-2">
                        <div>
                            <img :src="getBackendUrl+visa.country.flag_img" width="150" alt="">
                            <div class="mt-4">
                                <h5>{{ visa.label }}</h5>
                                <h5>{{ visa.title }}</h5>
                                <p>{{ visa.description }}</p>
                                <p class="h4"><strong>{{ $t('message.Price') }}: {{ visa.currency.currency }} {{ visa.price }}</strong></p>
                                <p class="h4 text-bold"><strong>
                                    Fee: {{ visa.currency.currency }} {{ visa.fee }}
                                </strong></p>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="7">
                    <div class="px-2">
                        <div class="d-flex justify-content-between">
                            <h3>Visa types</h3>
                            <div>
                                <b-button variant="info" size="md" @click="addTypeModal = true">+ Add</b-button>
                            </div>
                        </div>
                        <div class="mt-1">
                            <b-row>
                                <b-col v-for="(type, index) in visa_types" :key="index" cols="6" class="mt-1">
                                    <div class="d-flex justify-content-between">
                                        <div class="border p-1" style="width: 80%;">
                                            <h4>{{ type.name }}</h4>
                                        </div>
                                        <b-button variant="danger" @click="handleVisaTypeDelete(type.id)" style="width: 20%" >
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </div> 
                                </b-col>
                            </b-row>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <h3>Visa type entries</h3>
                            <div>
                                <b-button variant="info" size="md" @click="addTypeEntryModal = true">+ Add</b-button>
                            </div>
                        </div>
                        <div class="mt-1">
                            <b-row>
                                <b-col v-for="(type, index) in typeEntries" :key="index" cols="6" class="mt-1">
                                    <div class="d-flex justify-content-between">
                                        <div class="border p-1" style="width: 80%;">
                                            <h4>{{ type.name }}</h4>
                                        </div>
                                        <b-button variant="danger" @click="handleVisaTypeEntryDelete(type.id)" style="width: 20%" >
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </div> 
                                </b-col>
                            </b-row>
                        </div>

                        <div class="d-flex justify-content-between mt-3">
                            <h3>Consular processing time</h3>
                            <div>
                                <b-button variant="info" size="md" @click="addConsularModal = true">+ Add</b-button>
                            </div>
                        </div>
                        <div class="mt-1">
                            <b-row>
                                <b-col v-for="(type, index) in consularsList" :key="index" cols="6" class="mt-1">
                                    <div class="d-flex justify-content-between">
                                        <div class="border p-1" style="width: 80%;">
                                            <h4>{{ type.name }}</h4>
                                        </div>
                                        <b-button variant="danger" @click="handleProccessingTimeDelete(type.id)" style="width: 20%" >
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div class="px-2 mt-5" style="width: 75%">
                        <h4>{{ $t('RequiredCustomFields') }}</h4>
                        <draggable 
                            v-model="customField" 
                            group="customFields" 
                            @start="drag=true" 
                            @end="drag=false"
                            ghost-class="ghost"
                        >
                        <transition-group>
                            <div v-for="(item, index) in customField" :key="index" class="w-full mt-3 border border-solid p-2 shadow rounded">
                                <h5>{{ $t('message.Type') }}</h5>
                                <b-form-select v-model="item.field_type" :options="fieldTypes"></b-form-select>
                                <h5 class="mt-2">{{ $t('Name') }}</h5>
                                <b-form-input v-model="item.field_name" class=""></b-form-input>
                                <div class="mt-3 d-flex justify-content-end">
                                    <b-button variant="danger" @click="handleCustomFieldDelete(item, index)">
                                    <b-icon icon="trash"></b-icon></b-button>
                                </div>
                            </div>
                        </transition-group>
                        </draggable>
                        <div class="mt-2 d-flex justify-content-end">
                            <b-button @click="addNewObject"><b-icon icon="plus"></b-icon></b-button>
                        </div>
                        <div class="mt-3 d-flex justify-content-end">
                            <b-button variant="info" @click="handleSubmit">{{ $t('SubmitBtn') }}</b-button>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>

        <b-modal v-model="addTypeModal" hide-footer>
            <h4>{{ $t('AddNewVisaType') }}</h4>
            <b-form-group label="Name" class="mt-2">
                <b-form-input v-model="type_name"></b-form-input>
            </b-form-group>

            <div class="d-flex justify-content-end mt-3">
                <b-button variant="primary" @click="addNewVisaType">{{ $t('message.Add') }}</b-button>
            </div>
        </b-modal>
        <b-modal v-model="addTypeEntryModal" hide-footer>
            <h4>{{$t('AddVisaEntryType')}}</h4>
            <b-form-group label="Name" class="mt-2">
                <b-form-input v-model="type_entry_name"></b-form-input>
            </b-form-group>

            <div class="d-flex justify-content-end mt-3">
                <b-button variant="primary" @click="addNewVisaTypeEntry">{{ $t('message.Add') }}</b-button>
            </div>
        </b-modal>

        <b-modal v-model="addConsularModal" hide-footer>
            <h4>{{ $t('AddConsularProcessingTime') }}</h4>
            <b-form-group label="Name" class="mt-2">
                <b-form-input v-model="consular_name"></b-form-input>
            </b-form-group>

            <div class="d-flex justify-content-end mt-3">
                <b-button variant="primary" @click="addNewConsularProcessing">{{ $t('message.Add') }}</b-button>
            </div>
        </b-modal>
        <b-modal v-model="deleteCustomFieldModal" hide-footer hide-header>
            <h4>{{ $t('DoyouWantToDelete') }}</h4>
            <div class="d-flex justify-content-between pt-2">
                <b-button variant="danger" @click="removeCustomField">{{ $t('message.YesDelete') }}</b-button>
                <b-button variant="secondary" @click="deleteCustomFieldModal=false">{{ $t('message.Cancel') }}</b-button>
            </div>
        </b-modal>

        <b-modal v-model="deleteVisaTypeModal" hide-footer hide-header>
            <h4>{{ $t('DoyouWantToDelete') }}</h4>
            <div class="d-flex justify-content-between pt-2">
                <b-button variant="danger" @click="deleteVisaType">{{ $t('message.YesDelete') }}</b-button>
                <b-button variant="secondary" @click="deleteVisaTypeModal=false">{{ $t('message.Cancel') }}</b-button>
            </div>
        </b-modal>
        <b-modal v-model="deleteVisaTypeEntryModal" hide-footer hide-header>
            <h4>{{ $t('DoyouWantToDelete') }}</h4>
            <div class="d-flex justify-content-between pt-2">
                <b-button variant="danger" @click="deleteVisaTypeEntry">{{ $t('message.YesDelete') }}</b-button>
                <b-button variant="secondary" @click="deleteVisaTypeEntryModal=false">{{ $t('message.Cancel') }}</b-button>
            </div>
        </b-modal>
         <b-modal v-model="deleteProccessingTimeModal" hide-footer hide-header>
            <h4>{{ $t('DoyouWantToDelete') }}</h4>
            <div class="d-flex justify-content-between pt-2">
                <b-button variant="danger" @click="getdeleteProccessingTime">{{ $t('message.YesDelete') }}</b-button>
                <b-button variant="secondary" @click="deleteVisaTypeEntryModal=false">{{ $t('message.Cancel') }}</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { showCountryVisa, storeVisaType, getVisaTypes, storeVisaTypeEntries, getVisaTypeEntries, getConsularProcessing, storeConsularProcessing, storeVisaCustomField, deleteVisaCustomField, deleteVisaType, deleteVisaTypeEntry, deleteConsularProcessingTime } from '@/api/countries/country-visa';
import AppConfig from '@/libs/vue-axios.config';
import Swal from 'sweetalert2'
import draggable from "vuedraggable";

    export default {
        name: 'Details',
        components: {
            draggable,
        },
        data() {
            return {
                visa: null,
                addTypeModal: false,
                addTypeEntryModal: false,
                addConsularModal: false,
                type_name: '',
                visa_types: [],
                type_entry_name: '',
                typeEntries: [],
                consularsList: [],
                consular_name: '',
                fieldTypes: [
                    {value: 'text', text: 'Text'},
                    {value: 'file', text: 'File'},
                ],
                customField: [
                    {field_type: null, field_name: ''},
                ],
                deleteCustomFieldModal: false,
                customDeleteItem: null,
                deleteVisaTypeModal: false,
                visaTypeId: null,
                deleteVisaTypeEntryModal: false,
                visaTypeEntryId: null,
                deleteProccessingTimeModal: false,
                visaProccessingTimeId: null,
            }
        },
        computed: {
            getBackendUrl() {
                return AppConfig.backendPath
            }
        },
        created() {
            this.getCountryVisa()
            this.getVisaTypeList()
            this.getVisaTypeEntryList()
            this.getConsularProcessingList()
        },
        methods: {
            getCountryVisa() {
                showCountryVisa(this.$route.params.id).then(res => {
                    this.visa = res.data.data;
                    if (res.data.data.custom_fields.length)
                    this.customField = res.data.data.custom_fields;
                })
            },
            getVisaTypeList() {
                getVisaTypes({country_visa_id: this.$route.params.id}).then(res => {
                    this.visa_types = res.data.data
                })
            },
            addNewVisaType() {
                storeVisaType({name: this.type_name, country_visa_id: this.$route.params.id}).then(res => {
                    this.type_name = ''
                    this.addTypeModal = false;
                    this.getVisaTypeList()
                })
            },
            getVisaTypeEntryList() {
                getVisaTypeEntries({country_visa_id: this.$route.params.id}).then(res => {
                    this.typeEntries = res.data.data
                })
            },
            addNewVisaTypeEntry() {
                storeVisaTypeEntries({name: this.type_entry_name, country_visa_id: this.$route.params.id}).then(res => {
                    this.type_entry_name = ''
                    this.addTypeEntryModal = false;
                    this.getVisaTypeEntryList()
                })
            },
            getConsularProcessingList() {
                getConsularProcessing({country_visa_id: this.$route.params.id}).then(res => {
                    this.consularsList = res.data.data
                })
            },
            addNewConsularProcessing() {
                storeConsularProcessing({name: this.consular_name, country_visa_id: this.$route.params.id}).then(res => {
                    this.consular_name = ''
                    this.addConsularModal = false
                    this.getConsularProcessingList()
                })
            },
            addNewObject() {
                this.customField.push({field_type: null, field_name: ''})
            },
            handleSubmit() {
                this.customField.forEach(item => {
                    item.country_visa_id = this.$route.params.id;
                })
                let count = 1;
                this.customField.forEach(item => {
                    item.sort = count;
                    count++;
                })
                storeVisaCustomField(this.customField).then(res => {
                    this.getCountryVisa()
                    Swal.fire({
                        icon: 'success',
                        position: 'center',
                        title: this.$t('Created'),
                        text: this.$t('CustomFieldsAdded'),
                    })
                })
            },
            handleCustomFieldDelete(item, index) {
                if (item.id) {
                    this.customDeleteItem = item;
                    this.customDeleteItem.index = index
                    this.deleteCustomFieldModal = true;
                } else {
                    this.customField.splice(index,1)
                }
                
            },
            removeCustomField() {
                deleteVisaCustomField(this.customDeleteItem.id).then(res => {
                    // this.customField.splice(this.customDeleteItem.index, 1)
                    this.getCountryVisa()
                    this.deleteCustomFieldModal = false
                })
            },
            handleVisaTypeDelete(id) {
                this.visaTypeId = id
                this.deleteVisaTypeModal = true
            },
            deleteVisaType() {
                deleteVisaType(this.visaTypeId).then(() => {
                    window.location.reload()
                })
            },
            handleVisaTypeEntryDelete(id) {
                this.visaTypeEntryId = id
                this.deleteVisaTypeEntryModal = true
            },
            deleteVisaTypeEntry() {
                deleteVisaTypeEntry(this.visaTypeEntryId).then(() => {
                    window.location.reload()
                })
            },
            handleProccessingTimeDelete(id) {
                this.visaProccessingTimeId = id
                this.deleteProccessingTimeModal = true
            },
            getdeleteProccessingTime() {
                deleteConsularProcessingTime(this.visaProccessingTimeId).then(() => {
                    window.location.reload()
                })
            },
        }
    }
</script>